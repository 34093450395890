<template>
  <div class="index_warp">
    <!--  <div class="mode">当前处于{{userInfo.mode}}</div> -->
    <div class="top">
      <div class="left"
           @click="toPage('/course/reviewHandout')">
        <div class="text">
          复习讲义
        </div>
        <div class="content">
          巩固基础，把握重难点
        </div>
        <div class="images">
          <img src="@/static/course/icon-review@2x.png"
               alt="">
        </div>
      </div>

      <div class="left"
           @click="toPage('/lecture')">
        <div class="text">
          公益讲座
        </div>
        <div class="content">
          课程同步视频讲解
        </div>
        <div class="images">
          <img src="@/static/course/icon-video@2x.png"
               alt="">
        </div>
      </div>
      <div class="left"
           @click="toPage('/shcoolVideo')">
        <div class="text">
          本校视频
        </div>
        <div class="content">
          本校视频讲解
        </div>
        <div class="images">
          <img src="@/static/course/icon-video@2x.png"
               alt="">
        </div>
      </div>
    </div>

    <div class="bottom">
      <Tabs />
    </div>
    <!-- <Card ref="CardRef"
          style="margin-top:13rem"
          title="知识点训练"
          url="/knowledge"
          @toInfo="toInfo"
          btn_text="进入训练"
          :list="list" /> -->
  </div>
</template>

<script setup>
import { reactive, ref, toRefs } from '@vue/reactivity'
import Tabs from './components/tabs.vue'
import { onMounted } from '@vue/runtime-core'
import { instance } from '@/utils/http.js'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { getHomeKnowledge } from '@/api/knowledge.js'
// import Card from '@/views/components/scroll_card/index.vue'

const router = useRouter()
let list = ref([])
let timeInterValId = ref(0)
let userInfo = ref(null)
onMounted(() => {
  userInfo.value = JSON.parse(window.localStorage.getItem('userInfo'))
  getKnowledge()
})


// 跳转页面
const toPage = (str) => {
  router.push(str)
}
const getKnowledge = async () => {
  const { data } = await getHomeKnowledge()
  list.value = data.list
}

const toInfo = (item) => {
  let form = {
    knowledge_id: item.knowledge_id,
    subject_id: item.subject_id
  }
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('paper')
  window.sessionStorage.removeItem('testpaperCardIndex')
  router.push('/testpaper/paperinfo?isKnowledge=1&form=' + JSON.stringify(form))
}

/*let handoutList = ref([])
let resourceTypeList = ref([])
const getData = async () => {
  const { data: handoutData } = await instance({
    url: '/api/v1/lecture/random',
    method: 'get'
  });
  handoutData.map(item => {
    item.title = item.lecture_name;
    item.subject = item.subject_name;
    item.id = item.lecture_id;
    item.colorId = item.subject_id;
  });
  handoutList = handoutData;
  console.log('handoutList', handoutList)
  const { data: resourceTypeData } = await instance({
    url: '/api/v1/teaching/type',
    method: 'get'
  });
  resourceTypeList = resourceTypeData.list;
  console.log("resourceTypeList", resourceTypeList)
}
getData()*/

</script>

<style lang="scss" scoped>
.index_warp {
  // margin: 0 auto;
  margin-top: 10px;
  margin-left: 68rem;
  .mode {
    margin-bottom: 7px;
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
    text-indent: 5px;
  }

  .top {
    display: flex;
    margin-top: 80rem;
    .left {
      position: relative;
      width: 540rem;
      height: 370rem;
      background-image: url("../../static/index/zjcs.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-left: 80rem;
      // margin-left: 135rem;
      cursor: pointer;
      .text {
        font-size: 32rem;
        font-weight: 800;
        color: #000000;
        margin-top: 80rem;
      }
      .content {
        font-size: 22rem;
        font-weight: bold;
        color: #666666;
        margin-top: 18rem;
        width: 280rem;
      }
      .images {
        position: absolute;
        right: 82rem;
        top: 140rem;
        img {
          width: 160rem;
          height: 160rem;
        }
      }
    }
  }
  .opertion_img_left {
    width: 15px;
    height: 34px;
    position: absolute;
    top: 415px;
    z-index: 1666;
    left: 150px;
  }
  .opertion_img_rigth {
    width: 15px;
    height: 34px;
    position: absolute;
    top: 415px;
    z-index: 1666;
    left: 875px;
  }

  .bottom {
    // height: 230rem;
    margin-top: 30rem;
    position: relative;
    padding-left: 80rem;
  }
}
</style>